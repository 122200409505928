<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="modrForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('modrForm')"/>
        <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        <el-button @click="drawer = true" v-if="specialDataFlag && isShow" size="small" type="warning" class="vd_export1">分享账套</el-button>
    </div>
    <el-form ref="modrForm" :rules="rules" :model="modrForm" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="订单编号" prop="modr_no" >
            <el-input disabled v-model="modrForm.modr_no" clearable maxlength="30" show-word-limit placeholder="请填写订单编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交货时间" prop="modr_date">
            <el-date-picker
                v-model="modrForm.modr_date"
                type="date"
                placeholder="请选择交货时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="cptt_id">
            <!-- <el-select v-model="modrForm.cptt_id" placeholder="请选择账套工厂" clearable>
              <el-option
                  v-for="item in cpttFctrGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select> -->
            <el-input disabled v-model="modrForm.purc_cptt_name" placeholder="请输入采购方" clearable></el-input>
          </el-form-item>
          <el-form-item label="总金额" prop="modr_total">
            <el-input disabled @blur="modrForm.modr_total = helper.haveFour(modrForm.modr_total)" v-model="modrForm.modr_total" @input="modrForm.modr_total = helper.keepTNum1(modrForm.modr_total)" maxlength="11"  show-word-limit placeholder="请填写总金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="modr_cptt_id">
            <!-- <el-select v-model="modrForm.modr_cptt_id" placeholder="请选择合作单位" clearable>
              <el-option
                  v-for="item in cpttGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select> -->
            <el-input disabled  v-model="modrForm.modr_cptt_name" placeholder="请选择销售方" clearable></el-input>
          </el-form-item>
          <el-form-item label="其他金额" prop="extra_price">
            <el-input @change="extraPriceChange" @blur="modrForm.extra_price = helper.haveFour(modrForm.extra_price)" v-model="modrForm.extra_price" @input="modrForm.extra_price = helper.keepTNum1(modrForm.extra_price)" maxlength="11"  show-word-limit placeholder="请填写其他金额"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="备注" prop="modr_remark">
            <el-input v-model="modrForm.modr_remark" maxlength="50" placeholder="请填写备注" :autosize="{ minRows: 2, maxRows: 6 }"  type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow()">导入</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <ModrAddMtrl :flag="true" @confirmDia="confirmDia" v-if="flag" :isShow="isShow" @handleSelectionChange="handleSelect" :modrForm="modrForm"></ModrAddMtrl>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <ModrAddEditIn @cancelModrIn="cancelModrIn" @confirmIn="confirmIn" ref="ModrAddEditIn"></ModrAddEditIn>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="modrForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small"  v-if="acctPreseFlag" @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="cancelType()">取消</el-button>
          <el-button class="vg_mb_16" type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="modrForm.modr_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-select size="small" @change="acctSelect(scope)" :disabled="modrForm.modr_acct_list[scope.$index].modr_acct_share === 1 || !acctPreseFlag" v-model="modrForm.modr_acct_list[scope.$index].acct_id" placeholder="请选择账套">
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link type="primary" v-if="modrForm.modr_acct_list[scope.$index].modr_acct_share === 1">系统</el-link>
                <el-link type="danger" :disabled="!acctPreseFlag" class="vg_cursor" v-else @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import ModrAddEditIn from "@/views/PurchaseManagement/ModrManage/TabChild/Component/ModrAddEditIn";
import ModrAddMtrl from "@/views/PurchaseManagement/ModrManage/TabChild/Component/ModrAddMtrl";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import {modrAPI} from "@api/modules/modr";
export default {
  name: "ModrEditMain",
  components:{
    editHeader,
    inputUser,
    ModrAddEditIn,
    ModrAddMtrl,
  },
  data(){
    return{
      modrForm:{},
      rules:{
        modr_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        cptt_id:[
          { required: true, trigger: ['change','blur'], message: ' '},
        ],
        modr_cptt_id:[
          { required: true, trigger:['change','blur'], message: ' '},
        ],
        modr_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        extra_price:[ {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: "最大只能输入9999.9999并且只能输入数字且最多4位小数!",   
          },]
      },
      cpttFctrGroupOpt:[],
      cpttGroupOpt:[],
      remove_modr_mtrl_li:[],
      modr_mtrl_li:[],
      notice:false,
      isShow: true,
      btn:{},
      modrFormLi:[],
      stffForm:{},
      title:'',
      loadingFlag:true,
      drawer: false,
      direction: 'rtl',
      specialDataFlag:false,
      modrAcctCope:[],
      acctCopy:[],
      acctPreseFlag:false,
      flag:true
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.loadingFlag = true
      if(this.$route.query.form_id){
        this.getModrInfo();
      }
      this.getCpttPurchaser()
      this.getCpttId()
      this.getAccts()
      if(this.$cookies.get('userInfo').acct_id === 1){
        this.specialDataFlag = true
      }
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() / 1000;
      let modrForm = {};
      modrForm = Object.assign(modrForm,this.modrForm);
      this.modrFormLi = this.modrFormLi.filter(item => this.modrForm.modr_mtrl_list.every(item1 => item.modr_mtrl_id != item1.modr_mtrl_id));
      for(let i=0;i<this.modrFormLi.length;i++){
        this.modrFormLi[i].destroy_flag = 1
      }
      modrForm.modr_mtrl_list = modrForm.modr_mtrl_list.concat(this.modrFormLi);
      post(modrAPI.editModr,modrForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.isShow = true;
              this.$emit('isShow',this.isShow)
              this.initData()
            } else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'订单编号重复'
              })
              this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() * 1000;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() * 1000;
              // this.initData()
            }
          })
          .catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    // 获取账套accts
    getAccts(){
      get(acctAPI.getAllAcctsV1)
      .then(res =>{
        if(res.data.code === 0){
          this.acctCopy = res.data.data;
          for(let i=0;i<this.acctCopy.length;i++){
            if(this.$cookies.get('userInfo').acct_id === this.acctCopy[i].acct_id){
              this.acctCopy[i].disabled  = true
            }
          }
        }else if(res.data.code === 5){
          console.log('  ');
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 关闭特殊数据范围
    handleCloseDrawer(){
      let flag = false;
      for(let i=0;i<this.modrForm.modr_acct_list.length;i++){
        if(this.modrForm.modr_acct_list[i].acct_id === null){
          flag = true;
        }
      }
      if(flag){
        this.$message.warning('请把分享的账套补充完整')
      }else{
        if(this.acctPreseFlag){
          this.$message.warning('请先保存或取消分享账套的编辑')
        }else{
          this.drawer = false
        }
      }
    },
    // 保存分享账套
    preseType(){
      let temp = false
      for(let i=0;i<this.modrForm.modr_acct_list.length;i++){
        if(this.modrForm.modr_acct_list[i].acct_id === null){
          temp = true
        }
      }
      if(temp){
        this.$message.warning("请把账套名称补充完整")
      }else{
        this.$confirm("确认保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res)=>{
          let acctList = JSON.parse(JSON.stringify(this.modrForm.modr_acct_list))
          this.modrAcctCope = this.modrAcctCope.filter(item=>acctList.every(item1=>item.modr_acct_id !== item1.modr_acct_id))
          for(let i=0;i<this.modrAcctCope.length;i++){
            this.modrAcctCope[i].destroy_flag = 1
          }
          acctList = acctList.concat(this.modrAcctCope)
          if(acctList.length>0){
            post(modrAPI.modrShareAccts,{modr_id:this.modrForm.modr_id,modr_acct_list:acctList})
            .then(res=>{
              if(res.data.code === 0){
                this.$message.success("保存成功")
                // this.drawer = false
                this.acctPreseFlag = false
                this.initData()
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            })
          }else{
            this.$message.success("保存成功")
            this.acctPreseFlag = false
            this.initData()
          }
        })
        .catch(()=>{}) 
      }
    },
    // 取消分享账套
    cancelType(){
      this.$confirm("确认取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((res)=>{
        this.acctPreseFlag = false
        this.initData()
      })
      .catch(()=>{})
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id:null
      };
      this.modrForm.modr_acct_list.push(item)
    },
    // 特殊数据范围添加删除
    delType(scope){
      if(scope.row.disabled){
        this.$message.error("该账套不能删除")
      }else{
        this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.modrForm.modr_acct_list.splice(scope.$index,1)
        })
        .catch(() => {});
      }
    },
    // 选择账套重复判断
    acctSelect(val){
      if(val.row.acct_id !== null){
        for(let i=0;i<this.modrForm.modr_acct_list.length;i++){
          if(i !== val.$index){
            if(this.modrForm.modr_acct_list[i].acct_id === val.row.acct_id){
              val.row.acct_id = null;
              this.$message({
                type:'warning',
                message:'账套选择重复'
              })
              return ;
            }
          }
        }
      }
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    //删除选择的内容
    handleSelect(val){
      this.remove_modr_mtrl_li = JSON.parse(JSON.stringify(val));
    },
    // 打开导入物料信息小窗口
    addRow(){
      this.notice = true;
    },
    // 修改产品订单物料
    confirmDia(val,index){
      this.flag = false
      this.modrForm.modr_mtrl_list[index].podr_prod_str = val[0].podtValue
      this.modrForm.modr_mtrl_list[index].podr_prod_mtrl_id = val[0].podr_prod_mtrl_id;
      this.$nextTick(()=>{
        this.flag = true
      })
    },
    // 导入物料信息小窗口确定按钮
    confirmIn(val){
      this.modr_mtrl_li = JSON.parse(JSON.stringify(val));
      this.notice = false;
      for(let i=0;i<this.modr_mtrl_li.length;i++){
        this.modr_mtrl_li[i].id = this.modrForm.modr_mtrl_list.length+i
        this.modr_mtrl_li[i].podr_prod_str = ''
        this.modr_mtrl_li[i].podr_prod_mtrl_id = ''
        this.modr_mtrl_li[i].mtrl_weight = this.helper.reservedDigits(this.modr_mtrl_li[i].mtrl_weight)
        this.modr_mtrl_li[i].mtrl_width = this.helper.haveFour(this.modr_mtrl_li[i].mtrl_width)
      }
      this.modrForm.modr_mtrl_list = this.modrForm.modr_mtrl_list.concat(this.modr_mtrl_li);
      this.$refs.ModrAddEditIn.clear()
    },
    // 取消选择按钮
    cancelModrIn(){
      this.notice = false
    },
    // 其他金额修改影响总金额
    extraPriceChange(){
      let temp = 0;
      if(this.modrForm.modr_mtrl_list.length>0){
        for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
          if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num!==undefined && this.modrForm.modr_mtrl_list[i].modr_mtrl_price!==undefined){
            let num1 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)*100000
            let num2 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
        if(this.modrForm.extra_price){
          temp = temp + Number(this.modrForm.extra_price)
        }
        this.modrForm.modr_total = this.helper.haveFour(Number(temp));
      }
    },
    // 删除物料信息按钮
    delBank(){
      if(this.remove_modr_mtrl_li.length<=0){
        return this.$message.warning('至少选择一条数据')
      }
      this.modrForm.modr_mtrl_list = this.modrForm.modr_mtrl_list.filter(item => this.remove_modr_mtrl_li.every(item1 => item.id != item1.id));
      for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
        this.modrForm.modr_mtrl_list[i].id = i;
      }
      let temp = 0;
      if(this.modrForm.modr_mtrl_list.length>0){
        for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
          if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num!==undefined && this.modrForm.modr_mtrl_list[i].modr_mtrl_price!==undefined){
            let num1 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)*100000
            let num2 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
        if(this.modrForm.extra_price){
          temp = temp + Number(this.modrForm.extra_price)
        }
        this.modrForm.modr_total = this.helper.haveFour(Number(temp));
      }
    },
    // 获取采购方
    getCpttPurchaser(){
      get(cpttAPI.get_my_cptts_v1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttFctrGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取合作单位id
    getCpttId(flag){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //获取表单信息
    getModrInfo(){
      let modr_id = this.$route.query.form_id
      get(modrAPI.getModrById,{modr_id:modr_id})
          .then(res=>{
            if (res.data.code === 0) {
              this.modrForm = res.data.data.form;
              if(this.modrForm.modr_date){
                this.modrForm.modr_date = this.modrForm.modr_date*1000;
              }else{
                this.modrForm.modr_date = null
              }
              this.btn = res.data.data.btn;
              this.modrAcctCope = JSON.parse(JSON.stringify(this.modrForm.modr_acct_list))
              this.modrForm.modr_total = this.helper.haveFour(this.modrForm.modr_total)
              this.modrForm.extra_price = this.helper.haveFour(this.modrForm.extra_price)
              for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
                if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num){
                  this.modrForm.modr_mtrl_list[i].modr_mtrl_num = this.helper.haveFour(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)
                }
                if(this.modrForm.modr_mtrl_list[i].modr_mtrl_price){
                  this.modrForm.modr_mtrl_list[i].modr_mtrl_price = this.helper.haveFour(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)
                }
                if(this.modrForm.modr_mtrl_list[i].mtrl_weight){
                  this.modrForm.modr_mtrl_list[i].mtrl_weight = this.helper.reservedDigits(this.modrForm.modr_mtrl_list[i].mtrl_weight)
                }
                if(this.modrForm.modr_mtrl_list[i].mtrl_width){
                  this.modrForm.modr_mtrl_list[i].mtrl_width = this.helper.haveFour(this.modrForm.modr_mtrl_list[i].mtrl_width)
                }
                this.modrForm.modr_mtrl_list[i].id = i;
              }
              this.stffForm.stff_name = this.modrForm.stff_name;
              this.stffForm.dept_name = this.modrForm.dept_name;
              this.stffForm.cptt_abbr = this.modrForm.cptt_abbr;
              this.stffForm.dept_team_name = this.modrForm.dept_team_name;
              this.stffForm.user_id = this.modrForm.user_id;
              this.stffForm.dept_id = this.modrForm.dept_id;
              this.stffForm.stff_id = this.modrForm.stff_id;
              this.modrFormLi = JSON.parse(JSON.stringify(this.modrForm.modr_mtrl_list));
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.modrForm.modr_mtrl_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_exports{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_draw{
  padding: 20% 10%;
}
.vd_export1{
  position: absolute;
  top: 0;
  left: 120px;
}
</style>