<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ModrEditMain from "@/views/PurchaseManagement/ModrManage/TabChild/ModrEditMain";
import ModrEditAppr from "@/views/PurchaseManagement/ModrManage/TabChild/ModrEditAppr";
import ModrEditAbout from "@/views/PurchaseManagement/ModrManage/TabChild/ModrEditAbout";

export default {
  name: "ModrEdit",
  components: {
    childTab1: ModrEditMain,
    childTab2: ModrEditAppr,
    childTab3: ModrEditAbout
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  methods: {
    isShow(val){
      this.isSho = val;
    },
    draftState(){
      this.$refs.childTab1.initData()
    },
  }
}
</script>

<style scoped>

</style>